<template>
  <div class="custom-control custom-switch">
    <input
      type="checkbox"
      class="custom-control-input"
      v-bind:id="componentId + '-value'"
      ref="switch"
      v-bind:checked="gvalue"
      v-on:input="setSwitchValue"
    />
    <label
      class="custom-control-label"
      v-bind:for="componentId + '-value'"
      ref="label"
    >
    </label>
  </div>
</template>
<script>
export default {
  name: "TableSwitch",
  data(){
    return {
      gvalue: false
    }
  },
  props: {
    value: {
      default: () => false,
    },
    row: {
      type: Object,
      default: () => {},
    },
    column: {
      type: Object,
      default: () => {},
    },
  },
  watch:{
    value(){
      this.gvalue = this.value;
      if(Number(this.gvalue) != NaN) {
        this.gvalue = this.gvalue == 1 ? true : false
      } else {
        this.setSwitchStatus(this.gvalue);
      }
    }
  },
  computed: {
    componentId() {
      return (
        "wetable-content-row-cell-switch-" + helper.getRandomInteger(1, 999999)
      );
    },
  },
  methods: {
    setSwitchStatus(value) {
      if (this.$refs.switch) {
        this.$refs.switch.checked = value;
      }
    },
    setSwitchValue(e) {
      const value = e.target.checked;

      let data = {
        id: this.row.id,
        is_active: value,
        column: this.column ? this.column.name : null,
        row: this.row,
      };

      this.$parent.$parent.$parent.$emit("on-switch", data);

      this.setSwitchStatus(e.target.checked);
    },
  },
  mounted() {
    this.gvalue = this.value;
    if(Number(this.gvalue) != NaN) {
      this.gvalue = this.gvalue == 1 ? true : false
    } else {
      this.setSwitchStatus(this.gvalue);
    }
  },
};
</script>
